<template>
  <div class="dashboard-widget">
    <div v-if="$slots.title" class="dashboard-widget__header">
      <h5 class="dashboard-widget__title">
        <slot name="title" />
      </h5>
      <div class="dashboard-widget__actions">
        <slot name="actions" />
      </div>
    </div>

    <div class="dashboard-widget__inner" :class="[{ 'dashboard-widget__inner--padded': padded }]">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    padded: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.dashboard-widget {
  border-radius: $border-radius-xl;
  background-color: #fff;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $s-6;
  }

  &__title {
    font-size: $font-size-xl;
  }

  &__actions {
    > * + * {
      margin-left: $s-4;
    }
  }

  &__inner {
    &--padded {
      padding: $s-6;
    }
  }
}
</style>
