<template>
  <dashboard-widget>
    <template v-if="$fetchState.pending" />
    <t-count-widget v-else :label="$t('fields.total_organizations')" icon="building" card>
      {{ data.total_count }}
    </t-count-widget>
  </dashboard-widget>
</template>
<script>
import DashboardWidget from '~/pages/shared/dashboard/partials/DashboardWidget';
import Organization from '~/models/Organization';

export default {
  components: { DashboardWidget },

  data: () => ({
    data: null,
  }),

  async fetch() {
    this.data = await Organization.statistics();
  },
};
</script>
