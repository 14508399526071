<template>
  <dashboard-widget>
    <template v-if="$fetchState.pending" />
    <t-count-widget
      v-else
      :label="`${$t('fields.total_applicants')} (${$t('global.statuses.active')} / ${$t(
        'fields.total'
      )})`"
      icon="graduation-cap"
      card
    >
      <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
      {{ data.available_count }} / {{ data.total_count }}
    </t-count-widget>
  </dashboard-widget>
</template>
<script>
import DashboardWidget from '~/pages/shared/dashboard/partials/DashboardWidget';
import Applicant from '~/models/Applicant';

export default {
  components: { DashboardWidget },

  data: () => ({
    data: null,
  }),

  async fetch() {
    this.data = await Applicant.statistics();
  },

  computed: {
    value() {
      return this.data?.total;
    },
  },
};
</script>
<style></style>
