<template>
  <dashboard-widget>
    <template v-if="$fetchState.pending" />
    <t-count-widget v-else :label="$t('fields.total_employees')" icon="hard-hat" card>
      {{ data.total_count }}
    </t-count-widget>
  </dashboard-widget>
</template>
<script>
import DashboardWidget from '~/pages/shared/dashboard/partials/DashboardWidget';
import Employee from '~/models/Employee';

export default {
  components: { DashboardWidget },

  data: () => ({
    data: null,
  }),

  async fetch() {
    this.data = await Employee.statistics();
  },
};
</script>
<style></style>
